const React = require('react');
const { string, shape, arrayOf } = require('prop-types');
const Section = require('../../../../commons/section');
const withTracker = require('../../../../commons/with-tracker');
const BenefitItem = require('../benefit-item');
const LoyaltyBuyLevel6DesktopHeader = require('./header');

const LoyaltyBuyLevel6Desktop = ({
  header,
  benefits,
  benefits_title,
  action,
}) => (
  <Section className="loyalty-buylevel6">
    <div className="row container">
      <div className="loyalty-buylevel6__wrapper">
        <LoyaltyBuyLevel6DesktopHeader {...header} action={action} />
        <div className="loyalty-buylevel6__body">
          <span className="loyalty-buylevel6__body-title">
            {benefits_title}
          </span>
          <div className="loyalty-buylevel6__body-benefits">
            {benefits.map((item) => (
              <BenefitItem {...item} key={item.description} />
            ))}
          </div>
        </div>
      </div>
    </div>
  </Section>
);

LoyaltyBuyLevel6Desktop.propTypes = {
  header: shape(LoyaltyBuyLevel6DesktopHeader.propTypes),
  benefits: arrayOf(
    shape({
      icon: shape({
        url: shape({
          src: string,
        }),
      }),
      description: string,
    }),
  ),
  benefits_title: string,
  action: shape({
    label: shape({}),
    target: string,
  }),
};

module.exports = withTracker(LoyaltyBuyLevel6Desktop);
