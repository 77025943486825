const React = require('react');
const { string, shape } = require('prop-types');
const Image = require('../../../commons/image');

const BenefitItem = ({ icon, description }) => (
  <div className="loyalty-buylevel6__body-benefit">
    <div className="loyalty-buylevel6__body-benefit-logo">
      <Image
        className="loyalty-buylevel6__body-benefit-logo-img"
        src={icon?.url?.src}
        alt={icon?.alt || description}
        lazyLoad
      />
    </div>
    <span className="loyalty-buylevel6__body-benefit-text">{description}</span>
  </div>
);

BenefitItem.propTypes = {
  icon: shape({
    url: shape({
      src: string.isRequired,
    }),
    alt: string,
  }).isRequired,
  description: string.isRequired,
};

module.exports = BenefitItem;
